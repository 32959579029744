import { useState, useEffect, } from 'react';
import React from 'react';
import Web3 from 'web3';
import { ChakraProvider, HStack, useColorModeValue, theme, Spacer, Button, VStack, Heading, Divider, IconButton, useClipboard } from '@chakra-ui/react'
import { ColorModeSwitcher } from './ColorModeSwitcher';
import TokenPresaleABI from './TokenPresaleABI.json';
import { shortenAddress } from '@usedapp/core';
import { Logo } from './logo';
import { CopyIcon } from '@chakra-ui/icons';
import CopyToClipboard from 'react-copy-to-clipboard';

const CONTRACT_ADDRESS = '0x91098A6246d7c545879D6d4a2bD0Cd6Bd5005495'; // Replace with your contract address
const REFERRER_PLACEHOLDER = '0x1f27b5b63b2dc9A48C8e8C45aC3c445f6ABf33e7'; // Replace with the owner's address


function App() {

const [account, setAccount] = useState()
const [refferer, setRefferer] = useState()
const web3 = new Web3(Web3.givenProvider);
const tokenPresaleContract = new web3.eth.Contract(TokenPresaleABI, CONTRACT_ADDRESS);

//Variables copy refferal


// On app mount, perform action


useEffect(()=>{
function loadRefferer(){
  const ref = getReferrerFromUrl() || REFERRER_PLACEHOLDER;
  setRefferer(ref)
  }
  loadRefferer()
}, [refferer])

const connectWallet = async () => {
    const accounts = await web3.eth.requestAccounts();
  
  setAccount(accounts[0])
};

   // Function to buy with a specific amount of BNB
  const buy = async (bnbAmount) => {
    const accounts = await web3.eth.getAccounts();


    // Call the buy function in the contract
    try {
      await tokenPresaleContract.methods.buy(refferer).send({
        from: accounts[0],
        value: web3.utils.toHex(web3.utils.toWei(bnbAmount.toString(), 'ether')),
    });
      console.log(`Bought ${bnbAmount} BNB with referrer ${refferer}`);
    } catch (error) {
      console.error(`Failed to buy ${bnbAmount} BNB:`, error);
    }
  };

  // Function to get the referrer from the URL query string
  const getReferrerFromUrl = () => {
    const searchParams = new URLSearchParams(window.location.search);
    return searchParams.get('ref');
  };

  return (
    <ChakraProvider theme={theme}>
     <VStack>
      <HStack w={'full'} h={100} p={5} boxShadow={'2xl'} bgColor={useColorModeValue('cyan.900', 'gray.50')}>
        <Logo />
        <Spacer />
        <Button borderRadius={"xl"} colorScheme='orange' 
          onClick={!account ? () => connectWallet() : () => {}}
        >
          {account ? shortenAddress(account) : "Connect Wallet"}
        </Button>
        <ColorModeSwitcher />
      </HStack>
      <VStack w={'full'} p={5} borderRadius='3xl' boxShadow={'2xl'} bgColor={useColorModeValue('orange.400', 'gray.50')}>
      <p align="center">Add DigiRupi to your wallet!<br></br>Token Contract address: <b>0x00f7F8BA0C6922ed58f4DC56af23c21d5eb37288</b></p>
      <CopyToClipboard text='0x00f7F8BA0C6922ed58f4DC56af23c21d5eb37288'>
      <IconButton
          colorScheme={'cyan'}
          size='2'
          aria-label='copy DigiRupi Contract Address'
          icon={<CopyIcon />}>
          
        </IconButton>
        </CopyToClipboard>
      </VStack>
      <VStack  h={550} p={10} borderRadius='3xl' boxShadow={'2xl'}>
        <p align="center"><font size="3"><b>Get your <Heading 
            size={"xl"} 
            fontWeight={900} 
            bgGradient='linear(to-r, orange.300, yellow.400, orange.200)'
            bgClip="text"
            >
                DigiRupi
        </Heading> here.<br></br></b></font></p>
        <Divider orientation='horizontal'></Divider>
        <Button w='full' borderRadius={"xl"} colorScheme='orange'
          onClick={() => buy("0.05")}
        >Buy 3,000 DGRu with 0.05 BNB</Button>
        <Divider orientation='horizontal'></Divider>
        <Button w='full' borderRadius={"xl"} colorScheme='orange'
          onClick={() => buy("0.1")}
        >Buy 6,000 DGRu with 0.10 BNB</Button>
        <Divider orientation='horizontal'></Divider>
        <Button w='full' borderRadius={"xl"} colorScheme='orange'
          onClick={() => buy("0.5")}
        >Buy 30,000 DGRu with 0.50 BNB</Button>
        <Divider orientation='horizontal'></Divider>
        <Button w='full' borderRadius={"xl"} colorScheme='orange'
          onClick={() => buy("1")}
        >Buy 60,000 DGRu with 1 BNB</Button>
        <Divider orientation='horizontal'></Divider>
        <Button w='full' borderRadius={"xl"} colorScheme='orange'
          onClick={() => buy("02")}
        >Buy 1,20,000 DGRu with 2 BNB</Button>
        <Divider orientation='horizontal'></Divider>
        <Divider orientation='horizontal'></Divider>
        <VStack w={'full'} p={2} borderRadius='3xl' boxShadow={'2xl'} bgColor={useColorModeValue('orange.400', 'gray.50')}>
        {account ? <p align="center"><font size="4"><b>Reffer you friend & get 9% BNB & 1% DGRu!</b></font><br></br><Divider orientation='horizontal'></Divider><font size="3"><b>Your referral link is :<br></br></b></font><font size="1"><b>https://presaledigirupi.com/?ref={account}<br></br></b></font></p> : <p align="center"><font size="3"><b>Please connect your Wallet<br></br></b></font></p> }
        {account ? <CopyToClipboard text={"https://presaledigirupi.com/?ref="+account}><IconButton
          id='#btnCopy'
          colorScheme={'cyan'}
          size='2'
          aria-label='copy refferal link'
          icon={<CopyIcon />}
          >
          
        </IconButton></CopyToClipboard> : ""}

        </VStack>
        </VStack>
        <VStack w={600} p={10} borderRadius='3xl' boxShadow={'2xl'} bgColor={useColorModeValue('cyan.500', 'gray.50')}>
        <Heading 
            size={"xl"} 
            fontWeight={900} 
            bgGradient='linear(to-r, orange.300, yellow.400, orange.200)'
            bgClip="text"
            >
                DigiRupi
        </Heading>
          <Divider orientation='horizontal'></Divider>
          <p align="center"><font size="3"><b>DigiRupi is a
          versatile, decentralized crypto token built on the Binance Smart
          Chain, a blockchain that enables secure and efficient transactions
          across borders.With its 'reflection' system DigiRupi provides passive income by rewarding holders with BUSD. It's fast transaction speed and low fees, makes
          DigiRupi an ideal solution for those who need a reliable and
          accessible way to exchange value globally. DigiRupi's smart contract
          technology also allows for automated execution of tracsactions,
          ensuring the reliability and security of every exchange.</b></font></p>
        </VStack>    
      </VStack>
    </ChakraProvider>
  );
  }

export default App;
